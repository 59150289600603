import React from 'react';
import Fade from 'react-reveal/Fade';
import {SeoHeader} from "./Seo";


class OurVision extends React.Component{
    render() {
        return (
            <div className="container our_vision">
                <div className="row">
                    <div className="col-sm-12 col-lg-12">
                    <Fade top>
                        <h2 className="main_title black text-center">Our vision</h2>
                        <SeoHeader/>
                        <p>Guest satisfaction is the most important thing for us and we try with our quality of services to exceed their expectations.Our goal is to make every guest feel like at home and that for this reason he chooses Hotel Chitrakut again for his accommodation facility.We provide services at the highest level that will meet the needs and expectations of guests and will recognize their requirements through open communication, satisfaction monitoring and analysis of compliments and complaints.
                        </p>
                    </Fade>
                    </div>
                </div>
            </div>
        )
    }
    componentDidMount(){

    }
}


export default OurVision;
