import React from 'react';
import * as jQuery from 'jquery';
import { SeoImage } from "./Seo";

const doubleBedRoomImg = require('./assets/images/gallery/org/chitrakut-19.jpg');
const threeBedRoomImg = require('./assets/images/gallery/org/chitrakut-28.jpg');
const familyRoomImg = require('./assets/images/gallery/org/chitrakut-15.jpg');
const HallImg = require('./assets/images/gallery/org/chitrakut-20.jpg');

const rooms = [
    {
        title: 'Double Bed',
        src: doubleBedRoomImg,
        description: '',
        price: ''
    },
    {
        title: 'Three Bed',
        src: threeBedRoomImg,
        description: '',
        price: ''
    },
    {
        title: 'Family Room',
        src: familyRoomImg,
        description: '',
        price: ''
    },
    {
        title: 'Hall',
        src: HallImg,
        description: '',
        price: ''
    }
];

class Room extends React.Component {

    static renderRoom(room, index) {
        return (
            <div className="col-xs-12 col-sm-6 col-md-4 blogBox moreBox animated out"
                data-animation="fadeInUp" data-delay="0" key={index}>
                <div className="item">
                    <SeoImage src={room.src} />
                    <div className="blogTxt">
                        <h2 className="text-center">{room.title}</h2>
                        {/* <div className="price_room">{room.price}</div> */}
                        <p className="post_intro hidden-xs">{room.description}</p>
                        <div className="blogCategory">
                            <a href={'tel:+919925870037'}>Call Now</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <section id={'rooms'} className="room_bg">
                <div className="container ">
                    <div className="row">
                        <h2 className="main_title black text-center animated out" data-animation="fadeInDown"
                            data-delay="0">OUR ROOMS</h2>
                        <p className="cnt_txt">We offer 24 well-appointed rooms and 4 spacious dormitories (general halls), making Hotel Chitrakut
                            an ideal and budget-friendly choice for school tours and Sanghs. Each dormitory can accommodate
                            up to 25 guests, providing a comfortable and economical stay for large groups.</p>
                    </div>
                    <div className="row rooms">
                        {
                            rooms.map((room, index) => {
                                return Room.renderRoom(room, index);
                            })
                        }
                    </div>
                    <div id="loadMore" >
                        <button>SEE ALL ROOMS</button>
                    </div>
                </div>
            </section>
        )
    }

    componentDidMount() {
        jQuery(document).ready(function () {
            const $loadMore = jQuery("#loadMore");
            const $moreBox = jQuery(".moreBox");
            const $blogBoxHidden = jQuery(".blogBox:hidden");
            $moreBox.slice(0, 3).show();
            if ($blogBoxHidden.length !== 0) {
                $loadMore.show();
            }
            $loadMore.on('click', function (e) {
                const $moreBoxHidden = jQuery(".moreBox:hidden");
                e.preventDefault();
                $moreBoxHidden.slice(0, 6).slideDown();
                if ($moreBoxHidden.length === 0) {
                    $loadMore.fadeOut('slow');
                }
            });
        });
    }
}

export default Room;
