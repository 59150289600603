import React from "react";

// export const keywords = `Best Hotels in Virpur | Hotels near to Temple in Virpur | Budget Hotels in Virpur | Guest House near to Temple in Virpur | Top Hotels in Virpur | Best Budget Hotels in Virpur | Best Family Hotels in Virpur | Best Family Guest House in Virpur | Budget Guest House in Virpur | Best Accomodation in Virpur`;
export const keywords = `Best Hotel in Virpur Jalaram | Family Guest House - Chitrakut`;
// export const aboutUs = `Hotel Chitrakut is established  in 1992 and started the journey towards hospitality. Hotel Chitrakut is having more than 120 bed capacity which makes it largest Hotel in town. it is having 24 rooms and 4 dormitories (general halls) which makes it suitable and economical for school tours and sanghs as 25 person can stay in each.Bed capacity varies from 2 bed/room to 7 bed/room with optional A/c and non A/c facilities. each room is having hot/cold water availability for 24x7.`;
export const aboutUs = `Looking for the best hotel in Virpur near Jalaram Mandir? Stay at Chitrakut, a family guest house with budget-friendly rooms with good facilities. Book now!`;
export const newKeyWords = `virpur hotel hotel at virpur | hotel in virpur | virpur hotel| virpur hotels | hotel near virpur | virpur jalaram mandir hotel | hotel chitrakoot virpur | hotel chitrakoot virpur gujarat | virpur hotel booking | virpur hotel list | virpur hotel price | virpur hotels gujarat | virpur jalaram bapa hotel | virpur jalaram hotel | virpur jalaram temple hotel |Best Hotels in Virpur Jalaram | Hotels Near to Temple in Virpur Jalaram | Budget Hotels in Virpur Jalaram | Guest House near to Temple in Virpur Jalaram | Top Hotels in Virpur Jalaram| Best Budget Hotels in Virpur Jalaram| Best Family Hotels in Virpur Jalaram| Best Family Guest House in Virpur Jalaram | Budget Guest House in Virpur Jalaram | Best Accomodation in Virpur Jalaram`;
export const newSiteDescription = `Best Hotels in Virpur Jalaram | Hotels Near to Temple in Virpur Jalaram | Budget Hotels in Virpur Jalaram | Guest House near to Temple in Virpur Jalaram | Top Hotels in Virpur Jalaram| Best Budget Hotels in Virpur Jalaram| Best Family Hotels in Virpur Jalaram| Best Family Guest House in Virpur Jalaram | Budget Guest House in Virpur Jalaram | Best Accomodation in Virpur Jalaram`;
export const aboutJalaramBapa = `Jalaram Bapa (Gujarati: જલારામ બાપા) popularly known as Bapa (Gujarati: બાપા) (4 November 1856 – 23 February 1939) was a Hindu saint from Gujarat, India. He was born on 4 November 1856, one week after the Hindu festival of Diwali, which is associated with his Iṣṭa-devatā Lord Rama.`;
export const aboutVirpurJalaram = `It is the workplace of Shree Jalaram Bapa.The main shrine of Jalaram Bapa is located at Virpur. The shrine is actually the house complex where Jalaram lived during his lifetime. The shrine houses the belongings of Jalaram and the deities of Rama, Sita, Lakshamana and Hanuman worshipped by him. It also has on display the Jholi and Danda said to be given by God.[5] But the main attraction is the portrait of Jalaram Bapa. There is also an actual black and white photo of Jalaram Bapa, taken one year before his death.The temple is one of a kind in the world in a way that it has not been accepting any offerings since 9 February 2000.`;


export class SeoHeader extends React.Component {
    render() {
        return (
            <h1 style={{ 'display': 'none' }}>{aboutUs}</h1>
        );
    }
}

export class SeoImage extends React.Component {
    render() {
        return (
            <img {...this.props} alt={keywords} />
        );
    }
}

export class SeoHeaderWithTxt extends React.Component {
    render() {
        return (
            <h1 style={{ 'display': 'none' }}>{this.props.children}</h1>
        );
    }
}
