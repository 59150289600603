import React from 'react';
import Shake from 'react-reveal/Shake';
import {SeoHeader, SeoImage} from "./Seo";

class Amenities extends React.Component {
    render() {
        return (
            <div id={'amenities'} className="amenties_bg">
                <SeoHeader/>
                <div className="container">
                    <div className="row-fluid">
                        <h2 className="main_title white text-center">AMENITIES</h2>
                        <div
                            className="col-xs-12 col-sm-12 col-md-12 blogBox moreBox animated out"
                            data-animation="fadeInUp"
                            data-delay="0">
                            <div className="amenties">
                                <ul>
                                    <li>
                                        <div className="amt_box">
                                            <Shake>
                                                <SeoImage src={require('./assets/images/amt_01.png')}/>
                                                <h2>Car parking</h2>
                                            </Shake>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="amt_box">
                                            <Shake>
                                                <SeoImage src={require('./assets/images/amt_02.png')}/>
                                                <h2>Room Service</h2>
                                            </Shake>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="amt_box">
                                            <Shake>
                                                <SeoImage src={require('./assets/images/amt_03.png')}/>
                                                <h2>Free Hi Speed Wifi</h2>
                                            </Shake>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="amt_box nobg">
                                            <Shake>
                                                <SeoImage src={require('./assets/images/amt_04.png')}/>
                                                <h2>Doctor On Call</h2>
                                            </Shake>
                                        </div>
                                    </li>
                                    <li className="nobg">
                                        <div className="amt_box">
                                            <Shake>
                                                <SeoImage src={require('./assets/images/amt_05.png')}/>
                                                <h2>house keeping</h2>
                                            </Shake>
                                        </div>
                                    </li>
                                    <li className="nobg">
                                        <div className="amt_box">
                                            <Shake>
                                                <SeoImage src={require('./assets/images/amt_06.png')}/>
                                                <h2>Cctv Camera</h2>
                                            </Shake>
                                        </div>
                                    </li>
                                    <li className="nobg">
                                        <div className="amt_box">
                                            <Shake>
                                                <SeoImage src={require('./assets/images/amt_07.png')}/>
                                                <h2>Help & travel Desk</h2>
                                            </Shake>
                                        </div>
                                    </li>
                                    <li className="nobg">
                                        <div className="amt_box nobg">
                                            <Shake>
                                                <SeoImage src={require('./assets/images/amt_08.png')}/>
                                                <h2>television</h2>
                                            </Shake>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Amenities;
