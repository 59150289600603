import React from 'react';
import Fade from 'react-reveal/Fade';
import { SeoHeader, SeoImage } from "./Seo";

const aboutImg = require('./assets/images/gallery/org/chitrakut-26.jpg');

class About extends React.Component {
    render() {
        return (
            <div className="container" id="aboutUs">
                <Fade bottom>
                    <h1 className="main_heading text-center animation out" data-animation="fadeInUp" data-delay="0">Best Hotel in Virpur Near Jalaram Temple</h1>
                </Fade >

                <Fade center>
                    <p className="mb-5 main_paragraph text-center">Enjoy a comfortable and budget-friendly stay at Hotel Chitrakut, the best hotel in Virpur. We
                        welcome you with warm hospitality, treating every guest like family. Our friendly staff ensures a
                        pleasant and homely experience, making your stay truly memorable.</p>
                </Fade>

                <div className="row align-items-center">
                    <div className="col-lg-6 col-sm-12 animation out" data-animation="fadeInLeft" data-delay="0">

                        <div className="about_us_img">
                            <Fade left>
                                <SeoImage className="img-fluid" src={aboutImg} />
                                <SeoHeader />
                            </Fade>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 animation out" data-animation="fadeInRight" data-delay="0">
                        <Fade right>
                            <div className="about_us">
                                <h2>About Us</h2>
                                {/* <p>Hotel  Chitrakut is established  in 1992 and started the
                                    journey towards hospitality. Hotel Chitrakut is having more
                                    than 120 bed capacity which makes it largest Hotel in town.
                                    it is having 24 rooms and 4 dormitories (general halls) which
                                    makes it suitable and economical for school tours and sanghs
                                    as 25 person can stay in each.Bed capacity varies from 2 bed/room
                                    to 7 bed/room with optional A/c and non A/c facilities.
                                    each room is having hot/cold water availability for 24x7. </p> */}
                                <p>
                                    Established in 1992, Hotel Chitrakut has been a symbol of hospitality and comfort for decades.
                                    As the best guest house and hotel in Virpur, we provide accommodation for over 120 guests,
                                    ensuring a welcoming stay for pilgrims, families, school tours, and Sanghs.
                                </p>
                                <h6 className="mb-2">Our Facilities Include:</h6>
                                <ul className="list-unstyled">
                                    <li>✔ 24 well-furnished rooms (AC & Non-AC options)</li>
                                    <li>✔ 4 spacious dormitories (each accommodating up to 25 guests) – Ideal for group stays</li>
                                    <li>✔ Rooms with 2 to 7 beds, catering to different guest needs</li>
                                    <li>✔ 24/7 availability of hot and cold water for a comfortable experience</li>
                                </ul>
                                <p>
                                    {/* Jalram Bhojanalaya is located exactly opposite to hotel Chitrakut
                                    which is operated during festive season. */}
                                    Located conveniently near Jalaram Mandir, we provide a peaceful and budget-friendly stay for
                                    visitors. Opposite our hotel, Jalram Bhojanalaya operates during festive seasons, offering
                                    authentic meals for devotees.
                                </p>

                                <span>
                                    {/* Hotel  Chitrakut is established  in 1992 and started the
                                    journey towards hospitality. */}
                                    At Hotel Chitrakut, we strive to make your stay memorable with warm hospitality, modern 
                                    amenities, and a homely atmosphere. 
                                </span>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>

        )
    }
}

export default About;
