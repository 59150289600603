import React from 'react';
import Fade from 'react-reveal/Fade';
import { SeoHeader, SeoHeaderWithTxt } from "./Seo";

const attractions = [
    {
        source: require('./assets/images/place_01.jpg'),
        title: 'Khodaldham Temple',
        text: 'Khodaladham is beautiful and beautiful in every way, it is unique and the first of its kind. Spreading the nodes and activities of various activities in a wide area to move forward and move towards the community. It is indeed a spiritually advanced experience to witness the faith of the devotees.It is one of the largest Hindu temples, which is 299 feet long, 253 feet wide and 135 feet high, which covers a wide area.',
        wpLink: 'http://www.khodaldhamtrust.org/',
        topics: [
            'Huge Campus',
            'Magnificent Temple Of Maa Khodiyar',
            'Attractive Construction',
        ],
        distanceTxt: '7 KMs',
        direction: 'https://www.google.co.in/maps/dir/Virpur,+Gujarat+360380/Khodaldham+Temple,+Jetpur+Road,+Kagvad,+Gujarat/@21.8169815,70.6723599,13.59z/data=!4m14!4m13!1m5!1m1!1s0x39583e71b9e51fd3:0x3ceffe42e79baa52!2m2!1d70.6975176!2d21.8457287!1m5!1m1!1s0x39589ff11ef68687:0xa31dea34ebd2624b!2m2!1d70.6894489!2d21.7911263!3e0'
    },
    {
        source: require('./assets/images/place_02.jpg'),
        title: 'Junagadh',
        text: 'Junagadh district in the Indian state of Gujarat. The city is the 7th largest in Gujarat, located at the foot of the Girnar hills.It is also known as "Sorath",After a brief struggle between India and Pakistan, Junagadh joined India on 9 November 1947. It was a part of Saurashtra state and later Bombay state. In 1960, after the Maha Gujarat movement, it became part of newly formed Gujarat state.',
        wpLink: 'https://en.wikipedia.org/wiki/Junagadh',
        topics: [
            'Damodar Kund',
            'Bhavnath Ni Taleti',
            'Akshar Temple',
        ],
        distanceTxt: '45 KMs',
        direction: 'https://www.google.co.in/maps/dir/Virpur,+Gujarat+360380/Junagadh,+Gujarat/@21.6842163,70.4349794,11z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x39583e71b9e51fd3:0x3ceffe42e79baa52!2m2!1d70.6975176!2d21.8457287!1m5!1m1!1s0x3958018c6a277f53:0x13b52f8520a86e48!2m2!1d70.4578768!2d21.522184!3e0'
    },
    {
        source: require('./assets/images/place_03.jpg'),
        title: 'Sasan Gir ',
        text: 'Gir Forest National Park is a wildlife sanctuary in Gujarat, western India. It was established to protect Asiatic lions, who frequent the fenced-off Devalia Safari Park, It was established in 1965, with a total area of 1,412 KMs of which 258 KMs is fully protected as national park and 1,153 KMs as wildlife sanctuary.',
        wpLink: 'https://en.wikipedia.org/wiki/Gir_National_Park',
        topics: [
            'Gir National Park',
            'Devaliya Safari Park',
            'Jamjir Waterfall',


        ],
        distanceTxt: '100 KMs',
        direction: 'https://www.google.co.in/maps/dir/Virpur,+Gujarat+360380/Sasan+Gir,+Gujarat/@21.5061568,70.3574502,10z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x39583e71b9e51fd3:0x3ceffe42e79baa52!2m2!1d70.6975176!2d21.8457287!1m5!1m1!1s0x3be2b737f7d6a257:0xc25d1393aad1de08!2m2!1d70.5963974!2d21.170602!3e0'
    },
    {
        source: require('./assets/images/place_04.jpg'),
        title: 'Somnath Temple',
        text: 'The Somnath temple located in Prabhas Patan near Veraval in Saurashtra on the western coast of Gujarat, is believed to be the first among the twelve jyotirlinga shrines of Shiva.[1] It is an important pilgrimage and tourist spot of Gujarat.',
        wpLink: 'https://en.wikipedia.org/wiki/Somnath_temple',
        topics: [
            'First Among The Twelve Jyotirlinga',
            'Triveni Sangam',
            'Geeta Temple',
        ],
        distanceTxt: '140 KMs',
        direction: 'https://www.google.co.in/maps/dir/Virpur,+Gujarat+360380/Somnath,+Gujarat/@21.3744993,70.1797629,10z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x39583e71b9e51fd3:0x3ceffe42e79baa52!2m2!1d70.6975176!2d21.8457287!1m5!1m1!1s0x3bfd328b9ce28aeb:0x6d2efaa0d9eda083!2m2!1d70.3843721!2d20.9060022!3e0'
    },
    {
        source: require('./assets/images/place_05.jpg'),
        title: 'Diu',
        text: 'Diu District is the tenth least populated district of India.The town of Diu lies at the eastern end of Diu Island and is known for its fortress and old Portuguese cathedral. It is a fishing town.Diu will be competing for the one of last 10 spots against 20 cities from across India.',
        wpLink: 'https://en.wikipedia.org/wiki/Diu,_India',
        topics: [
            'Nagoa Beach',
            'Fort Diu',
            'Gangeshwar Temple',

        ],
        distanceTxt: '220 KMs',
        direction: 'https://www.google.co.in/maps/dir/Virpur,+Gujarat+360380/Diu,+Daman+and+Diu/@21.2801056,70.1784176,9z/data=!4m15!4m14!1m5!1m1!1s0x39583e71b9e51fd3:0x3ceffe42e79baa52!2m2!1d70.6975176!2d21.8457287!1m5!1m1!1s0x3be31ce77c7a67bf:0x4664503a0396202!2m2!1d70.9873719!2d20.7144094!3e0!5i2'
    },
    {
        source: require('./assets/images/place_06.jpg'),
        title: 'Dwarka',
        text: 'Dwarka is one of the foremost chardhams.four sacred Hindu pilgrimage sites, and is one of the Sapta Puri, the seven most ancient religious cities in the country. Dwarka is often identified with the Dwarka Kingdom, the ancient kingdom of Krishna, and is believed to have been the first capital of Gujarat.',
        wpLink: 'https://en.wikipedia.org/wiki/Dwarka',
        topics: [
            'Gomti ghat',
            'Bet Dwarka',
            'Sudama Setu',
        ],
        distanceTxt: '230 KMs',
        direction: 'https://www.google.co.in/maps/dir/Virpur,+Gujarat+360380/Dwarka,+Gujarat/@21.8572602,68.710367,8z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x39583e71b9e51fd3:0x3ceffe42e79baa52!2m2!1d70.6975176!2d21.8457287!1m5!1m1!1s0x39569c266399e37b:0xb5866e461a106e0a!2m2!1d68.9684562!2d22.2441975!3e0'
    }
];


class TouristAttractions extends React.Component {
    render() {
        return (
            <div className="testimonial_bg" id={'attractions'}>
                <div className="container blur_bg">
                    <div className="row">
                        <div className="col-sm-12 col-lg-12">
                            <h2 className="main_title black text-center"> Tourist Attractions </h2>
                            <SeoHeader />
                            <div className="tourist_main">
                                <ul>
                                    {
                                        attractions.map((obj, index) => {
                                            return (
                                                <Fade left key={index}>
                                                    <li>
                                                        <div className="place">
                                                            <img src={obj.source} alt={obj.title} />
                                                        </div>
                                                        <div className="place_cnt">
                                                            <h3>{obj.title} <span style={{ 'fontSize': '0.7em' }}>from</span> Virpur Jalaram</h3>
                                                            <SeoHeaderWithTxt>{obj.text}</SeoHeaderWithTxt>
                                                            <p className="comment more">{obj.text} <a target={'blank'}
                                                                href={obj.wpLink}>More</a>
                                                            </p>
                                                            {
                                                                obj.topics ? (
                                                                    <ul className={'points'}>
                                                                        {
                                                                            obj.topics.map((val, index) => {
                                                                                return (
                                                                                    <li key={index}>{val}</li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                ) : null
                                                            }
                                                            {obj.distanceTxt ?
                                                                <p className={'distance'}><a target={'blank'}
                                                                    href={obj.direction}><i
                                                                        className={'fa fa-road'}></i> From Virpur
                                                                    to {obj.title}</a>: {obj.distanceTxt}</p> : null}
                                                        </div>
                                                    </li>
                                                </Fade>
                                            )
                                        })
                                    }

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TouristAttractions;
